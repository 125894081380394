import { FunctionComponent, useRef, useState } from 'react';
import config from '@config';
import CustomHead from '@xFrame4/components/next/CustomHead';
import { useTranslate } from '@xFrame4/common/Hooks';
import HomePage1 from './home1/HomePage1';
import MainWrap from './layout/MainWrap';

interface HomePageProps
{

}

const HomePage: FunctionComponent<HomePageProps> = () =>
{
    const t = useTranslate();

    /** Render */
    return (
        <>
            <CustomHead metaData={
                {
                    title: t('APP_TITLE'),
                    metaDescription: t('META_DESCRIPTION'),
                    ogMeta: {
                        title: t('APP_TITLE'),
                        description: t('META_DESCRIPTION'),
                        url: config.appUrl,
                        image: {
                            url: config.appUrl + 'img/og-image.png',
                            type: 'image/png',
                            width: 1200,
                            height: 630
                        }
                    }
                }
            } />
            
            <MainWrap>
            
            <HomePage1 />

            </MainWrap>
        </>
    );
}

export default HomePage;