import { FunctionComponent } from 'react';
import config from '@config';
import { useTranslate } from '@xFrame4/common/Hooks';
import { AppTracker } from '@business/tracking/AppTracker';

interface HomePage_1Props
{

}

const HomePage1: FunctionComponent<HomePage_1Props> = (props) =>
{
    const t = useTranslate();

    /** Voucher benefits */
    let voucherBenefits = [
        {
            title: t('VOUCHER_BENEFIT_1_TITLE'),
            description: t('VOUCHER_BENEFIT_1_DESCRIPTION')
        },
        {
            title: t('VOUCHER_BENEFIT_2_TITLE'),
            description: t('VOUCHER_BENEFIT_2_DESCRIPTION')
        },
        {
            title: t('VOUCHER_BENEFIT_3_TITLE'),
            description: t('VOUCHER_BENEFIT_3_DESCRIPTION')
        },
        {
            title: t('VOUCHER_BENEFIT_4_TITLE'),
            description: t('VOUCHER_BENEFIT_4_DESCRIPTION')
        },
        {
            title: t('VOUCHER_BENEFIT_5_TITLE'),
            description: t('VOUCHER_BENEFIT_5_DESCRIPTION')
        },
        {
            title: t('VOUCHER_BENEFIT_6_TITLE'),
            description: t('VOUCHER_BENEFIT_6_DESCRIPTION')
        }
    ];

    let cmpVoucherBenefits = voucherBenefits.map((benefit, i) =>
        <div key={i} data-aos="fade-up" data-aos-delay="100" className="col-12 col-md-4" style={{ marginBottom: '2rem' }}>
            <div className="icon-element--round--box--border" style={{ height: '100%', backgroundColor: 'white' }}>
                <div className="info">
                    <div className="text">
                        <h6>{benefit.title}</h6>
                        <p>{benefit.description}</p>
                    </div>
                </div>
            </div>
        </div>
    );

    /** Client benefits */
    let clientBenefits = [
        {
            icon: 'feather icon-feather',
            title: t('CLIENT_BENEFIT_1_TITLE'),
            description: t('CLIENT_BENEFIT_1_DESCRIPTION')
        },
        {
            icon: 'feather icon-user',
            title: t('CLIENT_BENEFIT_2_TITLE'),
            description: t('CLIENT_BENEFIT_2_DESCRIPTION')
        },
        {
            icon: 'feather icon-maximize-2',
            title: t('CLIENT_BENEFIT_3_TITLE'),
            description: t('CLIENT_BENEFIT_3_DESCRIPTION')
        }
    ];

    let cmpClientBenefits = clientBenefits.map((benefit, i) =>
        <div key={i} data-aos="fade-up" data-aos-delay="100" className="col-12 col-md-4">
            <div className="icon-element--square--center">
                <div className="info">
                    <div className="icon--colortwo"><i className={benefit.icon}></i></div>
                    <div className="text">
                        <h6>{benefit.title}</h6>
                        <p>{benefit.description}</p>
                    </div>
                </div>
            </div>
        </div>
    );

    /** Render */
    return (
        <>
            <div className="page-header" style={{ marginBottom: '2rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="sectiontitle-element--center">
                                <span className="toptitle">{t('PAGE_HEADER_TOP_TITLE')}</span>
                                <h1 className="big">{t('PAGE_HEADER_BIG_TITLE')}</h1>
                                <p className="">{t('PAGE_HEADER_DESCRIPTION')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="page-content">
                <section style={{ marginBottom: '2rem' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="wrapimage-element">
                                    <img src={config.defaultPublicDirBaseUrl + 'img/home-1-section-1.webp'} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section data-padding data-bg="grey">
                    <div className="container">
                        <div className="row align-items-lg-center">
                            <div data-aos="zoom-in" data-aos-delay="200" className="col-12 col-lg-6">
                                <div className="square-element">
                                    <div className="info">
                                        <div className="image">
                                            <img data-unveil src="#" data-src={config.defaultPublicDirBaseUrl + 'img/home-1-section-2.webp'} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-aos="fade" className="col-12 col-lg-6">
                                <div className="sectiontitle-element">
                                    <h2>{t('INTERFACE_TITLE')}</h2>
                                    <p>{t('INTERFACE_DESCRIPTION_1')}</p>
                                    <p>{t('INTERFACE_DESCRIPTION_2')}</p>
                                    <p className="font-weight-bold">{t('INTERFACE_DESCRIPTION_BOLD')}</p>
                                    <div className="list-element">
                                        <ul>
                                            <li>{t('VOUCHER_TYPE_SPA_WELLNESS')}</li>
                                            <li>{t('VOUCHER_TYPE_RESTAURANT')}</li>
                                            <li>{t('VOUCHER_TYPE_ACCOMMODATION')}</li>
                                            <li>{t('VOUCHER_TYPE_CUSTOM')}</li>
                                            <li>{t('VOUCHER_TYPE_CUSTOM_VALUE')}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section data-padding>
                    <div className="container">
                        <div data-aos="fade" className="row">
                            <div className="col-12 col-lg-4" data-margin-bottom="none">
                                <div className="sectiontitle-element">
                                    <h2>{t('AUTOMATION_TITLE')}</h2>
                                </div>
                            </div>
                            <div className="col-12 col-lg-8">
                                <div className="sectiontitle-element--full">
                                    <p>
                                        {t('AUTOMATION_DESCRIPTION')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section data-padding data-bg="grey">
                    <div className="container">
                        <div data-aos="fade" className="row" style={{ marginBottom: '2rem' }}>
                            <div className="col-12">
                                <div className="sectiontitle-element--center">
                                    <h2>{t('WHY_SELL_VOUCHERS_TITLE')}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {cmpVoucherBenefits}
                        </div>
                    </div>
                </section>

                <section data-padding>
                    <div className="container">
                        <div data-aos="fade" className="row" style={{ marginBottom: '2rem' }}>
                            <div className="col-12">
                                <div className="sectiontitle-element--center">
                                    <h2>{t('WHY_CLIENTS_LOVE_VOUCHERS_TITLE')}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {cmpClientBenefits}
                        </div>
                    </div>
                </section>


                <section data-padding data-bg="grey">
                    <div className="container">
                        <div data-aos="fade-up" className="row">
                            <div className="col-12">
                                <div className="sectiontitle-element--center">
                                    <span className="toptitle">{t('TRY_NOW')}</span>
                                    <h2>{t('SEE_HOW_IT_WORKS')}</h2>
                                    <a target="_blank" rel="noreferrer" href="https://hunguest-fenyo.ro/voucher/js/www/?view=voucher-template-view&language=ro&idVoucherCategory=4" className="btn--big--arrow--round">{t('VOUCHERLOGICS_IN_ACTION')}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section data-padding>
                    <div className="container">
                        <div data-aos="fade-up" className="row">
                            <div className="col-12">
                                <div className="sectiontitle-element">
                                    <h2>{t('VOUCHERLOGICS_TITLE')}</h2>
                                    <p>
                                        {t('VOUCHERLOGICS_DESCRIPTION')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section data-aos="fade">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="ctabox-element--center--round--light">
                                    <div className="text">
                                        <h2>{t('INTERESTED_TITLE')}</h2>
                                        <p>{t('INTERESTED_DESCRIPTION')}</p>

                                        {/* <div>
                                            <a
                                                href="tel:0745268180" className="" style={{ color: 'white' }}
                                                onClick={() =>
                                                {
                                                    AppTracker.instance.logEvent('voucherlogics_click_phone_number', 'lead');
                                                }}
                                            >
                                                0745-268180
                                            </a>
                                        </div> */}
                                        <div>
                                            <a
                                                href="mailto:info@learnlogics.ro" className="btn--big--border--light--round" target="_self"
                                                onClick={() =>
                                                {
                                                    AppTracker.instance.logEvent('voucherlogics_click_email', 'lead');
                                                }}
                                            >
                                                info@learnlogics.ro
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default HomePage1;